
.multi-column-card__component {
    border: 2px solid transparent;
    align-items: center;
    color: var(--textPrimaryColor);
    display: flex;
    font-size: var(--bodyFontSize);
    justify-content: space-between;
    margin-bottom: 5px;
    text-align: left;
    width: 100%;
    word-break: break-word;

    
    &:not(.is-disabled):not(.is-selected) {
        background-color: var(--colorAdjustAlpha5);
    }

    &.is-disabled {
        border-color: var(--colorAdjustAlpha10);
        pointer-events: none;
    }

    &.is-selected {
        border-color: var(--colorAdjustAlpha20);
    }

    &.is-interactive {
        &:focus,
        &:active {
            border-color: var(--colorAdjustAlpha10);
        }

        &.is-selected:hover {
            cursor: pointer;
        }

        &:not(.is-selected):hover {
            border-color: var(--colorAdjustAlpha10);
            cursor: pointer;
        }
    }

    &.is-active-drop-target {
        border-color: var(--colorAdjustAlpha10);
        cursor: pointer;
    }

    &.size-md {
        padding: 10px 15px;
        min-height: 55px;
    }

    &.size-sm {
        padding: 5px 10px;
        min-height: 40px;
    }

}
