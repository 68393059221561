.login-carousel {
    max-width: 400px;
    width: 100%;
    text-align: center;
    animation-duration: 500ms;
    animation-name: carousel-show;

    h1, p {
        color: #fff;
    }
}

@keyframes carousel-show {
    from {
        transform: translateY(-5px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}