@use "./../shared/shared-element";

.switch {

    align-items: center;
    border-radius: 13px;
    display: flex;
    height: 26px;
    padding: 1px 2px 1px 1px;
    width: 50px;
    cursor: pointer;

    &:hover {
        filter: brightness(0.9);
    }

    &.is-disabled {
        @include shared-element.common-elt__is-disabled;
        pointer-events: none;
    }

    &.is-checked {
        &:not(.is-disabled) {
            background-color: var(--appHeaderBgColor);
        }

        .switch__handle {
            margin-left: 26px;
        }
      
    }

    &:not(.is-checked) {
        &:not(.is-disabled) {
            background-color: var(--colorAdjust30);
        }
        .switch__handle {
            margin-left: 3px;
        }
    }

    &:not(.is-checked, .is-disabled):hover {
        .switch__handle {
            background-color: var(--colorAdjust5);
        }
    }
}

.switch__handle {
    background-color: var(--colorAdjust0);
    border-radius: 10px;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
    height: 20px;
    transition: margin-left 0.3s ease;
    width: 20px;
}
