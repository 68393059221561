.color__accent {
    color: var(--colorAccent);
}

.bg-color__adjust-5 {
    background-color: var(--colorAdjust5) !important;
}

.bg-color__adjust-alpha-5 {
    background-color: var(--colorAdjustAlpha5) !important;
}

.color__adjust-5 {
    color: var(--colorAdjust5) !important;
}

.color__adjust-alpha-5 {
    color: var(--colorAdjustAlpha5) !important;
}

/** Opacity **/
@for $i from 0 through 10 {
    .bg-color__adjust-#{$i * 10} {
        background-color: var(--colorAdjust#{$i * 10}) !important;
    }

    .bg-color__adjust-alpha-#{$i * 10} {
        background-color: var(--colorAdjustAlpha#{$i * 10}) !important;
    }

    .color__adjust-#{$i * 10} {
        color: var(--colorAdjust#{$i * 10}) !important;
    }

    .color__adjust-alpha-#{$i * 10} {
        color: var(--colorAdjustAlpha#{$i * 10}) !important;
    }
}

.border-color__warning {
    border-color: var(--colorWarning) !important;
}

.color__warning {
    color: var(--colorWarning) !important;
}

.bg-color__warning {
    background-color: var(--colorWarning) !important;
}

.bg-color__app-header {
    background-color: var(--appHeaderBgColor);
}