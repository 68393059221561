.icon-button {

    color: var(--colorAdjust60);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.is-outline {
        width: 36px;
        height: 36px;
        border-color: var(--colorAdjustAlpha10);
        border-width: 1px;
        border-style: solid;
        border-radius: var(--elementBorderRadius);

        &:hover {
            border-color: var(--colorAdjustAlpha20);
            background-color: var(--colorAdjustAlpha10);
        }
    }

    &.is-inline {
        width: 24px;
        height: 24px;
    }

    &.is-disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &:hover {
        color: var(--colorAdjust100);
        cursor: pointer;
    }
}

.icon-button__badge {
    position: absolute;
    top: 5px;
    right: 5px;
}