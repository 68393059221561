$caret-size: 26px;

.caret {
    border-radius: 50%;
    width: $caret-size;
    height: $caret-size;
    min-width: $caret-size;
    min-height: $caret-size;
    padding: 4px;
    transition: transform 200ms ease;
    mix-blend-mode: difference;
    color: white;

    &:hover {
        background-color: var(--colorAdjustAlpha10);
    }
}

.caret.opened {
    transform: rotate(90deg);
}

.caret.closed {
    transform: rotate(0deg);
}