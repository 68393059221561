.multi-column-table__column {
    &:first-child {
        font-weight: 500;
    }
}

.multi-column-table__header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 15px;
    position: relative;
}

.multi-column-table__header-column {
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-right: 10px;

    &.is-active {
        opacity: 1;
    }

    &:not(.is-active) {
        opacity: 0.6;
    }

    &:hover {
        opacity: 1;
        cursor: pointer;
    }

    &.is-disabled {
        pointer-events: none;
    }
}

.multi-column-table__header__sort-column-spacer {
    width: 35px;
}

.multi-column-table__header__options-button {
    position: absolute;
    top: 2px;
    right: 2px;
}

.multi-column-table__column-body {
    text-overflow: ellipsis;

    &:not(.is-first-column){
        text-wrap: nowrap;
        overflow: hidden;
    }
}
