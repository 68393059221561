/* =============================================================================== */  
/* =============================================================================== */  
/* Margin & padding */

/* Margin and padding: Breakpoints */
@each $classlabel, $mediaquery in $breakpointmap {
	@media (#{$mediaquery}) {
		@for $i from 0 through 10 {
			$directionExpanded: none;
			
			.m-#{$i * 5}#{$classlabel} {margin: $i * 5 + px!important;}
			.rel-m-#{$i}#{$classlabel} {margin: calc(#{$i} * var(--spacing__base-unit)) !important;}
			@each $direction in $directionlist {
				@if $direction == t {$directionExpanded: top;}
				@if $direction == r {$directionExpanded: right;}
				@if $direction == b {$directionExpanded: bottom;}
				@if $direction == l {$directionExpanded: left;}
				
				@if $direction == x {
					.m#{$direction}-#{$i * 5}#{$classlabel} {margin-left: $i * 5 + px!important; margin-right: $i * 5 + px!important;}
					.rel-m#{$direction}-#{$i}#{$classlabel} {margin-left: calc(#{$i} * var(--spacing__base-unit)) !important; margin-right: calc(#{$i} * var(--spacing__base-unit)) !important;}
				} @else if $direction == y {
					.m#{$direction}-#{$i * 5}#{$classlabel} {margin-top: $i * 5 + px!important; margin-bottom: $i * 5 + px!important;} 
					.rel-m#{$direction}-#{$i}#{$classlabel} {margin-top: calc(#{$i} * var(--spacing__base-unit)) !important; margin-bottom: calc(#{$i} * var(--spacing__base-unit)) !important;}
				} @else {
				.m#{$direction}-#{$i * 5}#{$classlabel} {margin-#{$directionExpanded}: $i * 5 + px!important;}
				.rel-m#{$direction}-#{$i}#{$classlabel} {margin-#{$directionExpanded}: calc(#{$i} * var(--spacing__base-unit)) !important;}
				}
			} 
		}

		@for $i from 0 through 10 {
			@for $i from 0 through 10 {
			$directionExpanded: none;
			
			.p-#{$i * 5}#{$classlabel} {padding: $i * 5 + px!important;}
			.rel-p-#{$i}#{$classlabel} {padding: calc(#{$i} * var(--spacing__base-unit)) !important;}
			@each $direction in $directionlist {
				@if $direction == t {$directionExpanded: top;}
				@if $direction == r {$directionExpanded: right;}
				@if $direction == b {$directionExpanded: bottom;}
				@if $direction == l {$directionExpanded: left;}
				
				@if $direction == x {
					.p#{$direction}-#{$i * 5}#{$classlabel} {padding-left: $i * 5 + px!important; padding-right: $i * 5 + px!important;} 
					.rel-p#{$direction}-#{$i}#{$classlabel} {padding-left: calc(#{$i} * var(--spacing__base-unit)) !important; padding-right:  calc(#{$i} * var(--spacing__base-unit)) !important;} 
				} @else if $direction == y {
					.p#{$direction}-#{$i * 5}#{$classlabel} {padding-top: $i * 5 + px!important; padding-bottom: $i * 5 + px!important;} 
					.rel-p#{$direction}-#{$i}#{$classlabel} {padding-top: calc(#{$i} * var(--spacing__base-unit)) !important; padding-bottom:  calc(#{$i} * var(--spacing__base-unit)) !important;} 
				} @else {
				.p#{$direction}-#{$i * 5}#{$classlabel} {padding-#{$directionExpanded}: $i * 5 + px!important;} 
				.rel-p#{$direction}-#{$i}#{$classlabel} {padding-#{$directionExpanded}: calc(#{$i} * var(--spacing__base-unit)) !important;} 
				}
			} 
		} 
		}
	}
}
