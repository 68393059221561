@import './../../services/style/input';
@import './../shared/shared-element';

.button {
    font-weight: 600;

    &.contained,
    &.outlined {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        text-align: center;

        &.is-lg {
            min-width: 100px;
            padding: 0px 20px;
            height: 50px;
            border-radius: var(--buttonBorderRadius);
        }

        &.is-md {
            min-width: 50px;
            padding: 0px 15px;
            height: $ELEMENT_HEIGHT__MD;
            border-radius: var(--buttonBorderRadius);
        }

        &.is-sm {
            min-width: 50px;
            padding: 0px 15px;
            height: $ELEMENT_HEIGHT__SM;
            border-radius: var(--buttonBorderRadius);
        }

    }

    &.contained {
        color: var(--buttonContainedTextColor);

        &.primary {
            background-color: var(--buttonContainedBgColor);
        }

        &.secondary {
            background-color: var(--colorAdjust60);
        }

        &:focus {
            @extend .standard-component-focus-state;
        }
    }

    &.outlined {
        border-width: var(--elementBorderWidth);
        border-style: solid;

        &.primary {
            color: var(--buttonTextTextColor);
            border-color: var(--buttonTextTextColor);
        }

        &.secondary {
            color: var(--colorAdjust60);
            border-color: var(--colorAdjust60);
        }

        &:hover {
            background-color: var(--colorAdjustAlpha20);
        }

        &:active,
        &:focus {
            border-color: var(--colorAdjustAlpha80);
            box-shadow: 0px 0px 4px var(--colorAdjustAlpha40);
        }
    }

    &.text {

        padding: 0px;
        font-weight: 700;
        text-decoration: none;
        text-align: left;
        font-size: var(--bodyFontSize);

        &.primary {
            color: var(--buttonTextTextColor);
        }

        &.secondary {
            color: var(--colorAdjust70);
        }

        &:active,
        &:focus,
        &:hover,
        &.active {
            text-decoration: underline;

        }
    }

    &:hover {
        cursor: pointer;
        filter: brightness(0.8);
    }

    &:disabled {
        filter: saturate(0);
        opacity: 0.5;
        pointer-events: none;
    }

    .button__spinner__in-progress {
        width: 20px;
        position: absolute;
    }
}