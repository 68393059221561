.context-menu__popover {
    animation-duration: 0.25s;
    animation-name: context-menu-show;
    background-color: var(--contextMenuBgColor);
    border-radius: var(--standardElementBorderRadius);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.15);
    color: var(--colorAdjust90);
    display: flex;
    flex-direction: column;
    font-size: 10pt;
    overflow: auto;
    text-align: left;
    z-index: 10000;
    min-height: 40px;
    backdrop-filter: blur(3px);
}

@keyframes context-menu-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
