.quill {
    display: flex;
    flex-direction: column;
    background-color: var(--colorAdjust0);

    .ql-toolbar {
        border-top-left-radius: var(--elementBorderRadius);
        border-top-right-radius: var(--elementBorderRadius);
        border-color: var(--colorAdjustAlpha20);
    }

    .ql-editor img {
        max-width: 100%;
    }
}

.ql-container {
    height: 100%;
    overflow: auto;
    border-bottom-left-radius: var(--elementBorderRadius);
    border-bottom-right-radius: var(--elementBorderRadius);
    border-color: var(--colorAdjustAlpha20);
}