@import './../../services/style/settings';
$standard-route-padding: 30px;

.v2-route-wrapper {
    width: 100%; 
    height: 100vh;

    @media screen and (min-width: $md-min-breakpoint) {
        padding-top: 60px; 
        display: flex; 
    }

    /** We only need to add padding to the body if there is no 'route subnavigation' on this route. If subnavigation exists, the required
    padding is added to that element instead */
    @media screen and (min-width: $md-min-breakpoint) {
        .v2-route__body__outer:only-child {
            padding-left: var(--app-tray__padding-offset)
        }
    }
} 

.v2-route__body__outer {
    width: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
}

.v2-route__body__inner {
    width: 100%;
    max-width: 1450px;
    

    @media screen and (min-width: $md-min-breakpoint) {
        height: fit-content;
    }

}

.v2-route-body__p {
    padding: $standard-route-padding;
}

.v2-route-body__pt {
    padding-top: $standard-route-padding;
}

.v2-route-body__pb {
    padding-bottom: $standard-route-padding;
}

.v2-route-body__pl {
    padding-left: $standard-route-padding;
}

.v2-route-body__pr {
    padding-right: $standard-route-padding;
}
