.admin-footer__save {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 10;
    background-color: var(--colorAdjust0);
    display: flex;
    justify-content: end;
    padding: 10px;
    border-top: 1px solid var(--colorAdjust10);
}

.carousel-text-block {
    padding: 10px;
    background-color: var(--colorAdjust5);
    border-radius: var(--elementBorderRadius);
    margin-bottom: 10px;
    display: flex;
    position: relative;
}

.carousel-block__delete-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}