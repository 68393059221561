.list-item {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid var(--colorAdjustAlpha10);
    position: relative;
    cursor: pointer;

    &:hover {
        background-color: var(--colorAdjustAlpha10) !important;
    }

    &.is-selected {
        background-color: var(--colorAdjustAlpha5);
    }

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}