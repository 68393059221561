.paper {
    color: var(--contentBlockTextColor);
    border-radius: var(--elementBorderRadius);
    position: relative;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;

    &.bg-color__primary {
        background-color: var(--contentBlockBgColor);
    }

    &.bg-color__white {
        background-color: var(--colorAdjust0);
    }

    &.is-interactive {
        transform: translateY(0px);
        transition: box-shadow 200ms ease, transform 200ms ease;

        &:hover {
            border-color: var(--colorAdjustAlpha80);
            cursor: pointer;
        }
    }

    &.is-selected {
        pointer-events: none;
        filter: brightness(90%);
    }

    &.is-disabled {
        pointer-events: none;
        opacity: 0.65;
    }

    &:focus {
        border-color: var(--colorAccent);
    }

    &.is-elevated {
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.05);
    }
}