@import './../../../../services/style/card';

.lead-admin__card {
    @extend .base-card__hover-rules;
    @extend .base-card-rules;
    position: relative;
    padding-right:40px;
    display: flex;
    align-items: center;

    &.child {
        @extend .base-card__hover-rules;
    }
}
