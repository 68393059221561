@import './../../services/style/settings';

@media screen and (max-width: $sm-max-breakpoint) {
    .clamp-width-for-login-routes {
        width: 100%;
        max-width: 500px;
    }

    .login__body {
        width: 100%;
        height: calc(100vh - 40px);
    }
}

@media screen and (min-width: $md-min-breakpoint) {
    .clamp-width-for-login-routes {
        width: 350px;
    }

    .login__body {
        min-width: 50%;
        height: calc(100vh - 40px);
    }
    .clamp-width-for-rejoin-route {
        width: 700px;
    }
}

.login__logo {
    max-width: 200px;
    max-height: 40px;
}
.back-to-login {
    text-decoration: none;
}