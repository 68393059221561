.typogaphy__header {
    &.clamp-width {
        max-width: 700px;
    }
}

.font-size__xsmall {
    font-size: 9pt;
    line-height: 15pt;
}

.color__page-title {
    color: var(--appHeaderBgColor);
}

.text__color__disabled {
    color: rgb(130, 129, 136);
}

.text__color__secondary {
    color: var(--colorAccent);
}

.text__color__primary {
    color: rgb(25, 26, 27);
}

.text__color__warning {
    color: var(--colorWarning);
}

.text__color__accent {
    color: var(--colorAccent);
}

.text__variant__italic {
    font-style: italic;
}

.text__variant__center {
    text-align: center;
}