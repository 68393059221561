.icon-selector__option {
    width: 75px;
    height: 75px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: var(--colorAdjust0);
    display: flex;
    align-items: center;
    justify-content: center;

    &.selected {
        background-color: var(--buttonContainedBgColor);
        color: var(--buttonContainedTextColor);
    }

    &:not(.selected):hover {
        background-color: var(--colorAdjustAlpha5);
        cursor: pointer;
    }
}