@import './../../../services/style/settings';

.route-subnavigation {
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    overflow-y: auto;
    flex-shrink: 0;

    &.variant__standard {

    @media screen and (max-width: $sm-max-breakpoint) {
        padding-left: 20px;
        width: 100%;
    }

    @media screen and (min-width: $md-min-breakpoint) {
        padding-left: var(--app-tray__padding-offset);
        width: var(--subroute-navigation__width);
    }

}

    &.variant__wide {
        @media screen and (max-width: $sm-max-breakpoint) {
            padding-left: 20px;
            width: 100%;
        }
    
        @media screen and (min-width: $md-min-breakpoint) {
            padding-left: var(--app-tray__padding-offset);
            width: calc(var(--subroute-navigation__width) + 100px);
        }

    }
}