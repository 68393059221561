.base-popover {
    animation-duration: 0.25s;
    animation-name: popover-show;
    background-color: var(--colorAdjust5);
    color: var(--colorAdjust100);
    border-radius: var(--elementBorderRadius);
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);
    z-index: 1000;
}

@keyframes popover-show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.standard__popover {
    @extend .base-popover;
    padding: 20px;
    width: 500px;
    max-width: calc(100vw - 40px);
    max-height: 300px;
    overflow-y: auto;
}