.base-card-rules {
    background-color: var(--colorAdjustAlpha5);
    border-color: transparent;
    border-style: solid;
    border-width: 2px;
    color: var(--colorAdjust100);
    margin-bottom: 10px;
    min-height: 55px;
    padding: 10px 20px;
    width: 100%;
    border-radius: 3px;
}

.base-card__hover-rules {

    &:hover {
        border-color: var(--colorAdjustAlpha80);
        cursor: pointer;
    }
}