.payment-method-element {
    min-width: 250px;
}
.card-logo {
    min-height: 50px;
    display: flex;
}
.card-logo img {
    width: 100px;
    max-height: 50px;
}
.bank-name {
    min-height: 50px;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 1.1em;
}
.last-4 {
    height: 25px;
}
.exp-date {
    height: 22px;
    text-transform: capitalize;
    line-height: 22px;
}
.invoice-date {
    opacity: .8;
}
.invoice-amount {
    font-size: 1.3em;
    font-weight: bold;
}
.product-name {
    font-size: 1.3em;
    font-weight: bold;
}
.subscribe-button {
    min-width: 100px;
}
a.button {
    text-decoration: none;
}
.expiring-warning {
    color: #B22222;
    font-size: 16px;
}
.default-wrapper {
    display: flex;
    justify-content: flex-start;
    height: 20px;
    width: 100%;
}
.profile-nav-item {
    margin-bottom: 10px;
    text-decoration: none;
    color: #000;
    font-weight: bold;
}
a.profile-nav-item.active, a.profile-nav-item:hover {
    color: var(--buttonTextTextColor);
    text-decoration: underline;
}
.subscription-status {
  font-weight: bold;
  text-transform: capitalize;
}

.subscription-status.active {
  color: green;
}

.subscription-status.canceled {
  color: red;
}

.subscription-status.scheduled {
    color: #595959;
}


.card-brand, .edit-payment-method {
    text-transform: capitalize;
}
.card-brand img {
    width: 50px;
}
.card-brand .fa-pencil:hover, .payment-method-radio:hover {
    cursor: pointer;
}
.cancel-subscription-text {
    min-height: 88px;
    display: flex;
    align-items: center;
}
.multi-license-discount {
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
}