@import './../../services/style/settings';
@import './../../services/style/search-results';

.resource-card {
    text-decoration: none;
    position: relative;
    color: var(--resourceCardTextColor);
    border-radius: var(--elementBorderRadius);
    border-color: transparent;
    border-style: solid;
    border-width: 2px;

    &:focus {
        outline-color: var(--colorAccent);
        outline-width: 2px;
        outline-style: solid;
    }

    &:hover {
        cursor: pointer;
        border-color: var(--colorAdjustAlpha40);
    }

   
}

.unpublished-resource {
    opacity: .2 !important;
}

.resource-card.large {
    background-color: var(--resourceCardBgColor);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-right: 10px;
    min-height: 250px;
    padding: 10px;
    transform: translateY(0px);
    transition: box-shadow 200ms ease, transform 200ms ease;

    @media screen and (min-width: $md-min-breakpoint) {
        width: 275px;
    }

    @media screen and (max-width: $sm-max-breakpoint) {
        width: 100%;
    }

    .resource-card__top {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;
    }

    .resource-card__type-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
        font-size: 16px;
        color: var(--colorAdjust0);
        background-color: rgba(0, 0, 0, 0.5);
        min-width: 24px;
        padding: 5px;
        height: 24px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .resource-card__type-icon.unpublished {
        left: 10px !important;
        right: auto !important;
    }

    .resource-card__image {
        width: 100%;
        background-size: cover;
        background-color: var(--resourceCardImageBgColor);
        margin-bottom: 10px;
        height: 150px;
        border-radius: var(--elementBorderRadius);
        background-position: center center;
    }

    .malformed-resource-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;
        opacity: 0.5;
    }

}

.resource-card__floating-tag-wrapper {
    align-items: center;
    border-radius: 4px;
    color: var(--colorAdjust0);
    column-gap: 10px;
    display: flex;
    font-size: 16px;
    height: 24px;
    justify-content: center;
    min-width: 24px;
    padding: 5px;
    pointer-events: none;
    position: absolute;
    right: 13px;
    top: 13px;
    z-index: 10;

    &:not(:empty){
        background-color: rgba(0, 0, 0, 0.5);
    }

}



.resource-card.small {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    min-height: 75px;
    width: 300px;
    border: 1px solid var(--colorAdjustAlpha20);
    border-radius: var(--elementBorderRadius);
    padding: 20px;

    &:hover {
        cursor: pointer;
        background-color: var(--colorAdjust0);
    }

}


@media screen and (max-width: 767px) {
    .resource-card.subresource {
        flex-direction: column;
    }
}

.resource-card.subresource {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    min-height: 75px;
    background-color: var(--resourceCardBgColor);
    border-radius: var(--elementBorderRadius);
    transform: translateY(0px);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    transition: transform 250ms ease, box-shadow 250ms ease;

    &.col1 {
        width: 100%;
    }

    @media screen and (min-width: $md-min-breakpoint) {
        &.col2 {
            width: calc(50% - 10px);
            margin-right: 10px;
        }
    }

    @media screen and (max-width: $sm-max-breakpoint) {
        &.col2 {
            width: 100%;
        }
    }

    .resource-card__image {
        width: 75px;
        height: 60px;
        background-size: cover;
        background-color: var(--resourceCardImageBgColor);
        background-position: center center;
        border-radius: var(--elementBorderRadius);
        margin-right: 20px;
        flex-shrink: 0;
    }
    @media (max-width: 767px) {
        .resource-card__image {
            width: 100%;
            height: 110px;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    .resource-card__description {
        flex-shrink: 1;
    }


    &:hover {
        cursor: pointer;
    }

}

.resource-card.search-results {

    .resource-card__image {
        width: 75px;
        height: 50px;
        background-size: cover;
        background-color: var(--resourceCardImageBgColor);
        border-radius: var(--elementBorderRadius);
        margin-right: 10px;
        flex-shrink: 0;
    }

    .resource-card__description {
        flex-shrink: 1;
    }


}

.external-site-msg__wrapper {
    width: 500px;
    padding: 40px;
    border-radius: var(--elementBorderRadius);
    background-color: #fff;
    color: #000;
    text-align: center;
}

.read-more-ellipsis {
    height: 12px !important;
    display: inline-block !important;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
}
.read-more-ellipsis svg {
    vertical-align: 0.1em;
}