.theme-admin__border-style {
    border-width: 2px;
    border-color: var(--colorAdjustAlpha60);
    border-style: solid;
    width:75px;
    height:40px;
    margin-bottom: 10px;

    &.square {
        border-radius: 0px;
    }

    &.soft {
        border-radius: 3px;
    }

    &.round {
        border-radius: 20px;
    }
}

.theme-admin__border-width {
    border-color: var(--colorAdjustAlpha60);
    border-style: solid;
    width:75px;
    height:40px;
    margin-bottom: 10px;

    &.thin {
        border-width: 1px;
    }

    &.thick {
        border-width: 2px;
    }
}

