.base-checkbox-radio-input {
    align-items: center;
    border-width: var(--elementBorderWidth);
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.25);
    display: flex;
    flex-shrink: 0;
    height: 24px;
    justify-content: center;
    width: 24px;
    color: var(--buttonBgColor);

    &:hover {
        border-color: rgba(0, 0, 0, 0.75);
        background-color: var(--listOptionBgColorHover);
        cursor: pointer;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.standard-component-focus-state {
    outline-width: 2px;
    outline-style: solid;
    outline-color: var(--colorAdjustAlpha20);
    box-shadow: 0px 0px 4px var(--colorAdjustAlpha40);
}