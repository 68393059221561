.messages-route__body__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--colorAdjustAlpha10);
    padding: 10px 20px;
}

.messages-route__message {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    background-color: var(--colorAdjust0);
    border-radius: var(--elementBorderRadius);
}

.messages-route__message img {
    max-width: 100%;
}