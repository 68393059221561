.list-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: var(--elementBorderRadius);

    &.is-primary {
        background-color: var(--colorAdjustAlpha10);
    }

    &:hover {
        background-color: var(--listOptionBgColorHover);
        cursor: pointer;
    }

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .icon-wrapper {
        height: 20px;
        width: 20px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;
    }
}

.list-option__hot-key {
    opacity: 0.6;
}
