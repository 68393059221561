$standard-modal-padding: 20px;
$modal-animation-duration: 250ms;

.modal__scrim {
    align-items: center;
    animation-duration: $modal-animation-duration;
    animation-name: modal-scrim-open;
    backdrop-filter: blur(2px);
    background-color: rgba(26, 26, 26, 0.75);
    display: flex;
    height: 100vh;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1001;
}

.modal__wrapper {
    animation-duration: $modal-animation-duration;
    animation-name: modal-wrapper-open;
    background-color: #ffffff;
    background: var(--modalBgColor);
    border-radius: var(--elementBorderRadius);
    overflow: hidden;
    position: relative;
    max-width: calc(100vw - 60px);
    border-width: 0px;
    padding: 0px;

    &.height__fixed {
        height: 100%;
    }
}

@keyframes modal-scrim-open {
    from {
        opacity: 0;
        backdrop-filter: blur(0px);
    }

    to {
        opacity: 1;
        backdrop-filter: blur(2px);
    }
}

@keyframes modal-wrapper-open {
    from {
        transform: translateY(-30px);
    }

    to {
        transform: translateY(0px);
    }
}



.modal-body__p {
    padding: $standard-modal-padding;
}

.modal-body__pt {
    padding-top: $standard-modal-padding;
}

.modal-body__pb {
    padding-bottom: $standard-modal-padding;
}

.modal-body__pl {
    padding-left: $standard-modal-padding;
}

.modal-body__pr {
    padding-right: $standard-modal-padding;
}

.modal-body__sticky-header {
    background-color: var(--modalBgColor);
    position: sticky;
    top:0px;
    width: 100%;
    z-index: 10;
}

.modal__footer {
    display: flex;
    padding-left: $standard-modal-padding;
    padding-right: $standard-modal-padding;
    padding-bottom: $standard-modal-padding;
    padding-top: calc($standard-modal-padding / 2);
    background-color: var(--colorAdjust0);
}

.modal__header {
    display: flex;
    align-items: center;
    padding-left: $standard-modal-padding;
    padding-right: $standard-modal-padding;
    padding-top: $standard-modal-padding;
    padding-bottom: calc($standard-modal-padding / 2);
    background-color: var(--colorAdjust0);
}