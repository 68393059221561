@import './../services/style/settings';

.route__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.route__wrapper__inner {
    width: 100%;
    max-width: $max-page-width;
    padding-bottom: 40px;
    padding-top: 20px;
}

.route__body {
    width: 100vw;
    padding-right: 40px;
    justify-content: center;

    @media screen and (min-width: $md-min-breakpoint) {
        display: flex;
        margin-top: 60px;
        margin-right: 40px;
        padding-left: var(--app-tray__padding-offset);
        flex-grow: 1;
    }

    @media screen and (max-width: $sm-max-breakpoint) {
        padding: 20px;
    }
}

.hub__column__sidebar {

    @media screen and (min-width: $lg-min-breakpoint) {
        width: 350px;
        flex-shrink: 0;
    }

    @media screen and (max-width: $md-max-breakpoint) {
        width: 100%;
        margin-top: 50px;
    }
}

.hub__column__main {

    @media screen and (min-width: $lg-min-breakpoint) {
        flex-grow: 1;
        padding-right: 40px;
    }

    @media screen and (max-width: $md-max-breakpoint) {
        width: 100%;
    }
}