@import './../shared/shared-element';

.collapsible-content__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .collapsible-content__header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-radius: var(--elementBorderRadius);
        min-height: $ELEMENT_HEIGHT__MD;
        &:hover {
            cursor: pointer;
            background-color: var(--listOptionBgColorHover);
        }
    }

}