@import './../../services/style/settings';

.desktop-app-header {
    background-color: var(--appHeaderBgColor);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    color: var(--appHeaderTextColor);
    display: flex;
    flex-shrink: 0;
    height: 60px;
    justify-content: center;
    padding-bottom: 10px;
    padding-left: var(--app-tray__padding-offset);
    padding-top: 10px;
    position: absolute;
    width: 100vw;
    z-index: 10;

    .desktop-app-header__inner {
        align-items: center;
        display: flex;
        width: 100%;
    }

    @media screen and (max-width: $sm-max-breakpoint) {
        padding-right: 40px;
    }

    @media screen and (min-width: $md-min-breakpoint) {
        padding-right: 50px;
    }
}

.App-logo {
    &:hover {
        cursor: pointer;
        filter: brightness(80%);
    }
}