@import './../../services/style/settings';

.hub__header {
    background-position: top left;
    background-size: cover;
    border-radius: var(--elementBorderRadius);
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    overflow: hidden;
    width: 100%;
    position: relative;

    &.condensed {
        height: 50px;
        position: fixed;
        top: 60px;
        z-index: 10;
        max-width: $max-page-width;
    }

    .hub__header__mask {
        width: 100%;
        height: 100%;
        color: #fff;

        .route__wrapper__inner>* {
            max-width: 800px;
        }

        h1, h6 {
            color: #fff;
        }
    }

    .hub__header__stripe {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 6px;
    }

    .read-more-ellipsis svg {
        vertical-align: 0.1em;
        color: #000;
    }

}