.stepper__step {

    &.variant__button {
        padding: 7px 15px;
        border-radius: 6px;

        &.active {
            background-color: var(--colorAdjustAlpha20);
        }
    }

    &.variant__dots {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        border-style: solid;
        border-width: 2px;
        border-color: transparent;

        &:not(.active) {
            border-color: var(--colorAdjustAlpha80);
        }

        &.active {
            background-color: var(--colorAdjustAlpha80);
        }

        &:not(:last-of-type) {
            margin-right: 10px;
        }
    }
}