@import './settings';

body,
textarea,
input,
dialog,
p {
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.008);
    color: var(--colorAdjust90);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--bodyFontFamily);
    font-size: var(--bodyFontSize);
    font-weight: var(--bodyFontWeight);
    line-height: 160%;
}

button {
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.008);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--bodyFontFamily);
}

a {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--headerFontFamily);
    margin: 0px;
    line-height: 125%;
    color: var(--colorAdjust100);
}

h1 {
    font-size: var(--h1FontSize);
    font-weight: var(--h1FontWeight);
}

h2 {
    font-size: var(--h2FontSize);
    font-weight: var(--h2__font-weight);
}

h3 {
    font-size: var(--h3FontSize);
    font-weight: var(--h3FontWeight);
}

h4 {
    font-size: var(--h4FontSize);
    font-weight: var(--h4FontWeight);
}

h5 {
    font-size: var(--h5FontSize);
    font-weight: var(--h5FontWeight);
}

h6 {
    font-size: var(--h6FontSize);
    font-weight: var(--h6FontWeight);
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

@media screen and (max-width: $sm-max-breakpoint) {
    h1 {
        font-size: calc(var(--h1FontSize) * 0.5);
    }

    h2 {
        font-size: calc(var(--h2FontSize) * 0.7);
    }

    h3 {
        font-size: calc(var(--h3FontSize) * 0.8);
    }

    h4 {
        font-size: calc(var(--h6FontSize) * 0.9);
    }

    h5 {
        font-size: calc(var(--h5FontSize) * 0.9);
    }
}