@import './../../services/style/settings';

$app-tray-margin-bottom: 15px;

.destop-app-tray__visual-properties {
    align-items: flex-start;
    background-color: var(--appTrayBgColor);
    border-radius: var(--appTrayBorderRadius);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding-bottom: 20px;
    padding-top: 20px;

    .app-tray__logo__mark {
        width: 25px;
        margin-left: 16px;
    }

    .app-tray__logo__word {
        width: 150px;
        margin-left: 16px;
        flex-shrink: 0;
    }

    &:not(.expanded) {
        width: 60px;

        .app-tray__button {
            width: 50px;
        }
    }

    &.expanded {
        width: $app-tray-expanded-width;

        .app-tray__button {
            min-width: 240px;
            width: 240px
        }
    }



    .app-tray__button {
        align-items: center;
        color: var(--appTrayIconColor);
        display: flex;
        height: 48px;
        justify-content: flex-start;
        padding-left: 12px;
        margin-left: 5px;
        text-decoration: none;
        border-style: solid;
        border-width: 1px;
        border-color: transparent;

        &:hover {
            background-color: var(--colorAdjustAlpha40);
            color: #ffffff;
            cursor: pointer;
        }

        .app-tray__button__label {
            padding-left: 10px;
        }

        &:focus {
            background-color: var(--colorAdjustAlpha100);
            border-color: var(--colorAdjust40);
        }
    }

    .app-tray__home-btn {
        opacity: 0.6;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        overflow-x: hidden;

        &:hover {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
        }
    }

    .app-tray__section-header {
        color: #ffffff;
        letter-spacing: 2px;
        margin-bottom: 10px;
        margin-top: 10px;
        padding-left: 18px;
        font-weight: 700;
        transition: opacity 300ms ease;
        min-height: 24px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
    }

}

#desktop-app-tray {
    @extend .destop-app-tray__visual-properties;
    height: calc(100vh - 20px);
    margin: 10px;
    position: fixed;
    transition: width 250ms ease;
    z-index: 100;
    overflow: hidden;
    left: 0px;
}

.app-tray__divider {
    border-bottom:1px solid rgba(255,255,255,0.25);
}