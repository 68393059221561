.color-chip {
    width: 150px;
    height: 50px;
    padding: 5px;
    display: flex;
    align-items: center;

    .swatch {
        position: relative;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: var(--colorAdjust0);
        border: 1px solid var(--colorAdjust20);
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }
    }

    .swatch-inner {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

}

.color-chip__popover {
    z-index: 1000;
    .picker-wrapper {
        padding: 20px;
    }
}

.react-colorful {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    width: 250px !important;
    height: 250px !important;
}