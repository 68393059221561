.floating-action-button {
    background-color: var(--colorAdjust0);
    position: absolute;
    height: 32px;
    border-radius: 16px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        filter: brightness(90%);
    }

    &:not(.has-label){
        width: 32px;   
    }

    &.has-label{
        min-width: 32px;
        padding: 5px 10px;
    }
} 