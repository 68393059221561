

.base-search-filter {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 200px;

    .search-filter__option {
        margin-bottom: 10px;
    }
}