.toast__icon {
    flex-shrink: 0;
    width: 30px;
}

.toast__body {
    display: flex;
    align-items: start;
    font-size: 95%;
    line-height: 150%;
}