.drag-and-drop-file-uploader {
    border: 1px dashed var(--colorAdjustAlpha40);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    &:hover, &.drag-over {
        background-color: var(--colorAdjustAlpha10);
        cursor: pointer;
    }
}

