.login__img-bg__wrapper {
    border-radius: var(--elementBorderRadius);
    background-color: var(--colorAdjustAlpha20);
    overflow: hidden;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: sticky;
    height: calc(100vh - 40px);
    top: 20px;

    .login__img-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        background-size: cover;
    }


    .login__img-gradient {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
        width: 100%;
        height: 100%;
        opacity: 0.75;
    }

    .login__carousel-body {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 3;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }
}