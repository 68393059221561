@import './../../../../services/style/card';

.detailed-resource-admin__card {
    @extend .base-card-rules;
    position: relative;
    padding-right:40px;
}

.detailed-resource-admin__card__group-child {
    border: 1px solid var(--colorAdjustAlpha10);
    border-radius: var(--elementBorderRadius);
    margin-bottom: 5px;
    padding: 5px;
    
}

.detailed-resource-card-item {
    width: 20%;
    margin: 0 4px;
    flex-wrap: wrap;
    display: flex;
}

.detailed-resource-hide {
    visibility: hidden;
}

.detailed-resource-param-headers {
    width: 20%;
}