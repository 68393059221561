@import './../../../services/style/card';
@import './../../../services/style/search-results';

.user__card__img {

    background-color: var(--colorAdjust10);
    margin-right: 20px;
    border-radius: 4px;

    &.lg {
        width: 100px;
        height: 100px;
    }

    &.sm {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}