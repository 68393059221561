@import './../../../services/style/card';

.hub__card {
    @extend .base-card__hover-rules;
    @extend .base-card-rules;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 0px !important;

    .hub__icon-column {
        width: 80px;
        flex-shrink: 0;
        text-align: center;
    }


    &.child {
        @extend .base-card__hover-rules;
    }
}