.dropdown-option {
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: var(--elementBorderRadius);

    &:hover {
        background-color: var(--listOptionBgColorHover);
        cursor: pointer;
    }

    &:focus {
        outline: 1px solid var(--colorAdjust50);
    }
}

.dropdown__displayed-value {
    flex-grow: 1;
}