@import "./../shared/shared-element";
$TEXT_INPUT_PADDING: 5px 10px;
$TEXT_INPUT_SM_PADDING: 5px;

textarea.text-input {
    padding: $TEXT_INPUT_PADDING;
}

.text-input {
    border-radius: var(--elementBorderRadius);
    border-style: solid;
    border-width: 1px;
    position: relative;
    background-color: var(--colorAdjust0);
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--colorAjust100);

    &:not(.is-multiple-rows){
        height: $ELEMENT_HEIGHT__MD;
    }

    &.is-error {
        border-color: var(--colorWarning);
        

        &:hover {
            filter: brightness(80%);
        }
    }

    &:not(.is-error) {
        border-color: var(--colorAdjustAlpha20);

        &:hover {
            border-color: var(--colorAdjustAlpha40);
        }
    }

    &:not(.sm) {
        min-height: 42px;
    }

    &.sm {
        min-height: 40px;
    }

    &.light {
        background-color: rgba(255, 255, 255, 0.2);
        border-color: transparent;
        color: #fff;
    }

    &:disabled,
    &.disabled {
        opacity: 0.75;
        background-color: transparent;
        border-color: var(--colorAdjust40) !important;
    }

    .text-input__inner-content-wrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;

        input {
            flex-grow:1;
        }

        &:not(.sm) {
            padding: $TEXT_INPUT_PADDING;
        }

        &.sm {
            padding: $TEXT_INPUT_SM_PADDING;
        }
    }

    &.disabled input {


        border: 0px hidden;
        background-color: transparent;
    }

    &.editable input {

        border: 1px solid transparent;
        background-color: transparent;
        color: var(--colorAjust100);

    }

    &.data-type__number input {
        text-align: right;
    }

    &.data-type__number input {
        color: var(--colorAjust100);
    }


    &:focus,
    &:active {
        border-color: var(--colorAccent) !important;
    }

    &:focus-within {
        border-color: var(--colorAccent) !important;
    }


}

.input__no-frame {
    border: transparent !important;
    background-color: transparent !important;
    color: var(--colorAjust100) !important;
}

textarea.text-input {
    height: 100%;
}

input:autofill,
input:-internal-autofill-selected {
    background: red !important;
    background-color: red !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.non-disabled {
    pointer-events: all; /* Enable pointer events */
    opacity: 1; 
}