.progress-bar {
    height: 8px;
    min-width: 50px;
    background-color: var(--colorAdjustAlpha10);
    border-radius: 4px;

    .progress-bar__pct-complete {
        height: 100%;
        background-color: var(--colorAdjustAlpha60);
        border-radius: 4px;
    }
}