/* =============================================================================== */
/* =============================================================================== */
/* === HTML ELEMENT SETTINGS AND OVERRIDES === */

/* Remove webkit focus outline and background */

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input:focus,
button:focus,
select:focus {
	outline: none;
}

* {
	box-sizing: border-box !important;
}

div,
input[type="text"],
select {
	min-width: 0px;
}

/* CHANGED BLUR VALUE IN TEXT SHADOW TO 0px TO FIX IE / EDGE TEXT CUT OFF BUG */
html {
	height: 100%;
}

/* Less and Bootstrap overrides */
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px 0px;
}

ol,
ul {
	margin-top: 0px;
	margin-bottom: 0px;
}

@media (min-width: 768px) {
	body.highContrast {
		font-size: 14pt;
	}
}

textarea {
	resize: none;
}

div.disabled {
	pointer-events: none;
}

button {
	border: 0px hidden;
	background-color: rgba(0, 0, 0, 0);
}

*:focus {
	outline: none;
}

::selection {
	color: var(--buttonContainedTextColor);
	background: var(--buttonContainedBgColor);
}

// .infinite-scroll-component {
// 	// overflow-x: hidden !important;
// 	// overflow-y: auto !important;
// 	overflow: visible !important;
// }