.toggle-button {
    padding: 5px 10px;
    border: 1px solid var(--colorAdjustAlpha20);

    &.selected {
        background-color: var(--colorAdjustAlpha10);
    }

    &:not(.selected):hover{
        background-color: var(--colorAdjustAlpha5);
        cursor: pointer;
    }

    &:not(:last-child){
        border-right: transparent;
    }

    &:first-child {
        border-top-left-radius: var(--elementBorderRadius);
        border-bottom-left-radius: var(--elementBorderRadius);
    }

    &:last-child {
        border-top-right-radius: var(--elementBorderRadius);
        border-bottom-right-radius: var(--elementBorderRadius);
    }
}