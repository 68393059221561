$side-sheet-width__normal: 450px;
$side-sheet-width__wide: 700px;
$side-sheet-padding: 20px;

.side-sheet__component {
    backdrop-filter: blur(5px);
    background-color: var(--sideSheetBgColor);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
    color: var(--colorAdjust100);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    pointer-events: auto !important;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 10;
    max-width: 100vw;

    &.width-normal {
        animation-duration: 0.25s;
        animation-name: side-sheet-show__normal;
        width: $side-sheet-width__normal;
    }

    &.width-wide {
        animation-duration: 0.40s;
        animation-name: side-sheet-show__wide;
        width: $side-sheet-width__wide;
    }
}

.side-sheet__sticky-header {
    background-color: var(--sideSheetHeaderBgColor);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0px;
    z-index: 10;

    .title-bar {
        display: flex;
        justify-content: space-between;
        padding: $side-sheet-padding $side-sheet-padding 0 $side-sheet-padding;

        &:not(.includes-tabs) {
            padding: $side-sheet-padding $side-sheet-padding $side-sheet-padding $side-sheet-padding;
        }
        &.includes-tabs {
            padding: $side-sheet-padding $side-sheet-padding 0 $side-sheet-padding;
        }
    }
}

.side-sheet__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $side-sheet-padding $side-sheet-padding $side-sheet-padding $side-sheet-padding;
}

.side-sheet__footer {
    background-color: var(--sideSheetBgColor);
    border-top: 1px solid var(--colorAdjustAlpha10);
    bottom: 0px;
    padding: 20px;
    position: sticky;
}

@keyframes side-sheet-show__normal {
    from {
        opacity: 0;
        transform: translateX($side-sheet-width__normal);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes side-sheet-show__wide {
    from {
        opacity: 0;
        transform: translateX($side-sheet-width__wide);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.side-sheet__section.is-open {
    margin-bottom: 10px;
}

.side-sheet__section__header {
    align-items: center;
    border-radius: var(--standardElementBorderRadius);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 5px;
    width: 100%;

    &:hover {
        background-color: var(--colorAdjustAlpha5);
        color: var(--buttonTextColorHover);
        cursor: pointer;
    }
}

.side-sheet__section__body {
    background-color: var(--sideSheetSectionBgColor);
    border-radius: var(--standardElementBorderRadius);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow-y: hidden;
}

.side-sheet__section__body-inner {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.side-sheet__sub-section {
    border: var(--elementBorderWeight) solid var(--colorAdjustAlpha20);
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    border-radius: var(--standardElementBorderRadius);
}

.scrim__click-indicator {
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
    animation-name: click-indicator-hide;
    background-color: var(--buttonBgColor);
    border-radius: 6px;
    box-shadow: 0px 0px 5px var(--buttonBgColor);
    height: 12px;
    pointer-events: none;
    position: fixed;
    width: 12px;
    z-index: 1000000;
}

@keyframes click-indicator-hide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.scrim__click-indicator__elt {
    border: var(--elementBorderWeight) solid var(--buttonBgColor);
}
