@import './../../services/style/settings';

.mobile-app-header {
    display: flex;
    flex-shrink: 0;
    height: 60px;
    justify-content: center;
    padding: 10px 30px 10px 20px;
    position: sticky;
    width: 100%;
    z-index: 10;
    top:0px;

    &.normal {
        background-color: var(--appHeaderBgColor);
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }

    .app-header__inner {
        max-width: $max-page-width;
        width: 100%;
        display: flex;
        align-items: center;
    }

   
}
