.badge {
    background-color: var(--colorAccent);
    flex-shrink: 0;



    &.is-empty {
        width: 8px;
        height: 8px;
        border-radius: 4px;
    }

    &.is-not-empty {

        &.size-sm {
            min-width: 15px;
            height: 15px;
            font-size: 10px;
            padding: 2px 4px;
            border-radius: 4px;
        }
    
        &.size-md {
            min-width: 22px;
            height: 22px;
            font-size: 10px;
            padding: 2px 4px;
            border-radius: 7px;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }
}

.desktop-app-header .badge {
    &.is-not-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding-top: 3px;
        position: absolute;
        top: -5px;
        right: -5px;
    }
}