@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500&display=swap');

.font-wrapper {
    border: solid 1px #66A5B4;
}
.font-examples {
    font-size: 1.5em;
    line-height: 1.6em;

    .light {
        font-weight: 300;
    }

    .regular {
        font-weight: 400;
    }

    .condensed {
        font-weight: 500;
    }
}
