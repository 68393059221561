$sm-min-breakpoint: 768px;
$md-min-breakpoint: 1000px;
$lg-min-breakpoint: 1200px;
$xl-min-breakpoint: 1400px;
$appHeader-height: 60px;
$mobileKeyboardOpen-height: 150px;
$max-page-width: 1400px;

$app-tray-expanded-width: 250px;

$xs-max-breakpoint: $sm-min-breakpoint - 1;
$sm-max-breakpoint: $md-min-breakpoint - 1;
$md-max-breakpoint: $lg-min-breakpoint - 1;
$lg-max-breakpoint: $xl-min-breakpoint - 1;

$directionlist: t b l r x y;

$breakpointmap : (
"": "min-width:1px",
-xs: "min-width:1px",
-xs-down: "max-width:"$xs-max-breakpoint,
-sm: "min-width:"$sm-min-breakpoint,
-sm-down: "max-width:"$sm-max-breakpoint,
-md: "min-width:"$md-min-breakpoint,
-md-down: "max-width:"$md-max-breakpoint,
-lg: "min-width:"$lg-min-breakpoint,
-lg-down: "max-width:"$lg-max-breakpoint,
-xl: "min-width:"$xl-min-breakpoint
);