.tooltip__popover {
    background-color: var(--colorAdjust0);
    width: 100px;
    padding: 5px 10px;
    font-size: 90%;
    border-radius: var(--elementBorderRadius);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.tooltip-icon {
    min-width: 20px;
    min-height: 20px;
    border-radius: var(--elementBorderRadius);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
}