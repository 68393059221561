.notifications-popover {
width: 450px;
height: 550px;
background-color: var(--colorAdjust0);
color: var(--colorAdjust100);
border-radius: var(--elementBorderRadius);
display: flex;
flex-direction: column;
}

.notifications-popover__view-all-btn {
    flex-shrink: 0;
    background-color: var(--colorAdjustAlpha5);
    padding:20px;
    text-align: center;

    &:hover {
        background-color: var(--colorAdjustAlpha10);
        cursor: pointer;
    }
}