.options-panel-wrapper {
    position: relative;
}
.options-panel {
    position: absolute;
    z-index: 10000
}
.filter-options:hover, .filter-specialties:hover {
    background-color: var(--listOptionBgColorHover);
    border-radius: var(--elementBorderRadius);
}