@import './../../services/style/popover';

.calendar__event__popover {
    @extend .base-popover;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 600px;
    min-height: 200px;
    padding: 40px;
    text-overflow: ellipsis;
}

.fc a {
    text-decoration: none !important;
}

.fc-event-time {
    flex-shrink: 0;
}

.fc-event-time {
    flex-shrink: 0;
}

.fc-toolbar-title {
    font-size: 12pt !important;
}

.fc-toolbar.fc-header-toolbar {
    padding: 5px;
    margin-bottom: 0px !important;
    border-right: 1px solid rgb(221, 221, 221);
    border-left: 1px solid rgb(221, 221, 221);
    border-top: 1px solid rgb(221, 221, 221);
}