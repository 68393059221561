.user-dropdown-menu__wrapper {
    width: 400px;
    border-radius: var(--elementBorderRadius);
    background-color: var(--colorAdjust0);
}

.user-dropdown-menu__option {
    padding: 10px 20px;
    display: flex;
    align-items: center;

    &.prepend-divider {
        border-top: 1px solid var(--colorAdjustAlpha20);
    }

    &:hover {
        background-color: var(--colorAdjustAlpha10);
        cursor: pointer;
    }
}

.user-dropdown-menu__option-icon {
    min-width: 20px;
    min-height: 20px;
    margin-right:20px;
}


.user-dropdown-menu__alert {
    background-color: var(--colorWarning);
    color: var(--colorAdjust0);
    display: flex;
    align-items: center;
    padding: 10px 20px;

    &:hover {
        filter: brightness(80%);
        cursor: pointer;
    }
}