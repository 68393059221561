@import './../../services/style/input';

.news-feed__story-preview {
	color: inherit;
    background-color: transparent;
    border-radius: var(--elementBorderRadius);
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 10px;
    text-decoration: none;
    transform: translateY(0px);
    transition: background-color 250ms ease;

    &:hover {
        background-color: var(--colorAdjustAlpha5);
        cursor: pointer;
    }

    &:focus {
        @extend .standard-component-focus-state;
    }

}