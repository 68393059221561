.lokation__table {

    tbody td, thead td {
        border-bottom: 1px solid var(--colorAdjustAlpha10);
        padding-top: 10px;
        padding-bottom: 10px;
    }

    thead {
        font-weight: 700;
    }


}