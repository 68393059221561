.chip {
    min-width: 50px;
    min-height: 12px;
    border-radius: var(--elementBorderRadius);
    padding: 2px 5px;
    border-width: 1px;
    border-style: solid;
    font-size: 85%;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;  

    &.is-interactive:hover {
        cursor: pointer;
        filter: brightness(0.8);
        background-color: var(--colorAdjustAlpha10);
    }
}

.chip__remove-btn {
    opacity: 0.5;

    &:hover {
        opacity: 1.0;
        cursor: pointer;
    }
}