.status {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
    margin-left: 8px;
  }
  
  .status.green {
    background-color: green;
  }
  
  .status.orange {
    background-color: orange;
  }
  
  .status.red {
    background-color: red;
  }
  
  .status.purple {
    background-color: purple;
  }
  
  .status.gray {
    background-color: gray;
  }
  