@import './../../services/style/input';

.radio-button {
    @extend .base-checkbox-radio-input;
    border-radius: 50%;
}

.radio-button__circle {
    width:12px;
    height:12px;
    border-radius: 50%;
    background-color: var(--colorAdjust80);
}

.radio-button__large {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--colorAdjustAlpha10);
    width: 150px;
    height: 150px;

    &:hover {
        cursor: pointer;
        background-color: var(--colorAdjustAlpha20);
    }

    &.selected {
        background-color: var(--colorAdjustAlpha40);
    }
}