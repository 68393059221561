.mobile-app-tray {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    animation-duration: 0.25s;
    animation-name: mobile-app-tray-show;
    animation-timing-function: ease;
    backdrop-filter: blur(4px);
    z-index: 100;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;

    .mobile-app-tray__link {
        width: 100%;
        color: #ffffff;
        font-weight: 600;
        height: 60px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            background-color: rgba(0,0,0,0.1);
        }
    }

}

@keyframes mobile-app-tray-show {
    from {
        height: 0px;
    }

    to {
        height: 100vh;
    }
}