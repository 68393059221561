@import './settings';



body {
    background-size: cover;
    margin: 0px;
    padding: 0px;
    background: var(--pageBgColor);
    background-attachment: fixed;
    cursor: default;
    margin: 0;
    overflow-x: hidden;

    * {
        box-sizing: border-box !important;
    }

    &:has(.modal__scrim) {
        overflow-y: hidden;
    }
}

#root {
    width: 100vw;
}



.app__body {
    display: flex;
    flex-grow: 1;
    height: calc(100vh - 60px);
}

.overflow-y__scroll {
    overflow-y: auto !important;
}

.clamp-width-of-images img {
    max-width: 100%;
}

/** Opacity **/
@for $i from 0 through 20 {
    .opacity-#{$i * 5} {
        opacity: $i * 0.05 + !important;
    }
}

/** Border **/
@each $classlabel,
$mediaquery in $breakpointmap {
    @media (#{$mediaquery}) {
        .border-thin#{$classlabel} {
            border: 1px solid var(--colorAdjustAlpha30);
        }

        .border-top-thin#{$classlabel} {
            border-top: 1px solid var(--colorAdjustAlpha30);
        }

        .border-right-thin#{$classlabel} {
            border-right: 1px solid var(--colorAdjustAlpha30);
        }

        .border-bottom-thin#{$classlabel} {
            border-bottom: 1px solid var(--colorAdjustAlpha30);
        }

        .border-left-thin#{$classlabel} {
            border-left: 1px solid var(--colorAdjustAlpha30);
        }
    }
}

.border-radius__std {
    border-radius: var(--elementBorderRadius);
}

.show-if-only-child {

    &:not(:only-child) {
        display: none;
    }
}
.hide-lg {
    display: none;
}

@media screen and (max-width: $xs-max-breakpoint) {
    .show-sm {
        display: none !important;
    }
}

@media screen and (max-width: $sm-max-breakpoint) {
    .show-md {
        display: none !important;
    }
    
}

@media screen and (max-width: $md-max-breakpoint) {
    .show-lg {
        display: none !important;
    }
    .hide-lg {
        display: block;
    }
}


a {
    color: var(--buttonTextTextColor);
}