.hub-header__favorites-button {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 5px 10px;

    .favorites-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    color: var(--buttonContainedBgColor);
    }

    .favorites-text {
        color: var(--buttonTextColor);
        line-height: normal;
    }
   
    &:hover {
        cursor: pointer;
        filter: brightness(0.8);
        background-color: var(--colorAdjustAlpha10);
    }
}