.route-navigation-link {
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    border-radius: 3px;

    &:hover {
        cursor: pointer;
        background-color: var(--colorAdjustAlpha10);
    }

    &.is-active {
        color: var(--buttonContainedBgColor);
        background-color: var(--colorAdjustAlpha5);
        pointer-events: none;
    }
}

.route-navigation-link__label {
font-weight: 600;
margin-left:10px;
font-size: 10.5pt;
}