@import './../../../../services/style/card';

.resource-admin__card {
    @extend .base-card__hover-rules;
    @extend .base-card-rules;
    position: relative;
    padding-right:40px;

    .thumbnail-img {
        width: 100px;
        height: 50px;
        background-size: cover;
        border-radius: var(--elementBorderWidth);
        margin-right: 20px;
        background-position: center center;
    }

    &.child {
        @extend .base-card__hover-rules;
    }
}

.resource-admin__card__group-child {
    border: 1px solid var(--colorAdjustAlpha10);
    border-radius: var(--elementBorderRadius);
    margin-bottom: 5px;
    padding: 5px;
    
}
