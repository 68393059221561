.image-upload-tile {
    width: 150px;
    height: 150px;
    flex-shrink: 0;
    display: flex;
    padding: 10px;
}

.image-upload-tile__image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--colorAdjust40);
    position: relative;
    border-radius: var(--elementBorderRadius);
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.image-upload-tile__image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
}

.image-upload__hover-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: var(--colorAdjustAlpha20);
    backdrop-filter: blur(3px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--colorAdjust0);
    pointer-events: none;
}