* {
    scrollbar-color: var(--colorAdjust60) var(--colorAdjustAlpha20);
}

*::-webkit-scrollbar {
    width: 10px;
    /* Mostly for vertical scrollbars */
    height: 10px;
    /* Mostly for horizontal scrollbars */
}

*::-webkit-scrollbar-thumb {
    /* Foreground */
    background: var(--colorAdjust60);
}

*::-webkit-scrollbar-thumb:active, ::-webkit-scrollbar-thumb:hover {
    /* Foreground */
    background: var(--buttonBgColor);
}

*::-webkit-scrollbar-track {
    /* Background */
    background: var(--colorAdjustAlpha20);
}