.option-list__option {
    width: 100%;
    padding: 10px;

    &.selected {
        pointer-events: none;
        background-color: var(--colorAdjustAlpha5);
    }

    &:hover {
        cursor: pointer;
        background-color: var(--colorAdjustAlpha10);
    }
}