@import './../../services/style/settings';

.resource-card-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-left: 5px;
    padding-right: 5px;
}

.route__header {
    margin-bottom: 20px;
    z-index: 5;
    background-color: var(--pageBgColor);
    padding: 10px;
    border-bottom: 1px solid var(--colorAdjustAlpha10);

    @media screen and (max-width: $sm-max-breakpoint) {
        top: 60px;
    }

    @media screen and (min-width: $md-min-breakpoint) {
        top: 0px;
    }
}

.route__quick-links {
    background-color: var(--pageBgColor);
    padding: 10px;
    border-bottom: 1px solid var(--colorAdjustAlpha10);
}

.resource-section-selector {
    text-decoration: none;
    color: var(--colorAccent);

    &.selected {
        color: var(--coloAdjust80);
    }

    &:hover {
        text-decoration: underline;
    }
}

.resource-list__section-header {
    z-index: 4;
    background-color: var(--pageBgColor);
    position: sticky;
    padding: 10px;

    @media screen and (max-width: $sm-max-breakpoint) {
        top: 60px;
    }

    @media screen and (min-width: $md-min-breakpoint) {
        top: 0px;
    }
}