.tabs-option {
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-width: 3px;
    border-bottom-style: solid;

    &.selected {
        border-bottom-color: var(--buttonBgColor);
        opacity: 1;
    }

    &:not(.selected) {
        border-bottom-color: var(--colorAdjustAlpha5);
        opacity: 0.75;
    }

    &:hover {
        cursor: pointer;
        opacity: 1;
    }
}
