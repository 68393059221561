.scrim-component {
    animation-duration: 0.5s;
    animation-name: scrim-show;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    transition:
        backdrop-filter 250ms ease,
        background-color 250ms ease;
    z-index: 10;

    &.is-visible {
        backdrop-filter: contrast(0.75) blur(0.5px);
        background-color: rgba(0, 0, 0, 0.15);
        background-color: rgba(0, 0, 0, 0.25);
    }

    &:not(.is-visible) {
        backdrop-filter: contrast(1) blur(0px);
        background-color: rgba(0, 0, 0, 0);
        pointer-events: none;
    }
}

@keyframes scrim-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
