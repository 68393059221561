#powrIframeLoader {
    display: none;
}
.notice-image {
    height: 250px;
    width: auto;
    background-image: url('./lokation-bkg.jpg');
    background-position: center center;
    margin-bottom: 20px;
    
    .mask {
        background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.5) 100%);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px
    }

    .text {
        font-size: 1.9em;
        color: #fff;
        font-weight: bold;
    }
}

.responsive-video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
  }
  
  .responsive-video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  