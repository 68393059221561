$ELEMENT_HEIGHT__MD: 42px;
$ELEMENT_HEIGHT__SM: 32px;

/** States */
/** ========================================== */

@mixin common-elt__border-radius {
    border-radius: var(--elementBorderRadius);
}

@mixin common-elt__is-disabled {
    color: var(--colorAdjust50);
    background-color: var(--colorAdjustAlpha10);
    border-color: var(--colorAdjustAlpha10);
    text-overflow: ellipsis;

    input {
        color: var(--textDisabledColor);
    }
}

@mixin common-elt__has-focus {
    cursor: pointer;
    border-color: var(--buttonTextTextColor);
    box-shadow: 0px 0px 5px 0px var(--colorAdjustAlpha10);
}           

@mixin common-elt__has-hover {
    cursor: pointer;
    background-color: var(--colorAdjustAlpha5);
    border-color: var(--colorAdjustAlpha10);
    color: var(--colorAdjust100);
}

@mixin common-elt__is-selected {
    border-color: var(--colorAdjustAlpha5);
    background-color: var(--colorAdjustAlpha10);
    color: var(--colorAdjust100);
}

@mixin common-elt__is-selected__is-hover {
    cursor: pointer;
    filter: brightness(0.8);
}

@mixin common-elt__can-have-focus {
    @include common-elt__border-radius;
    border-color: transparent;
    border-width: var(--elementBorderWidth);
    border-style: solid;
}

@mixin common-elt__has-error {
    color: var(--colorWarning) !important;
    border-color: var(--colorWarning) !important;
}

/** ======================================== */

.element__width__100px {
    width: 100px;
}

.element__width__200px {
    width: 200px;
}

.element__width__250px {
    width: 250px;
}

.element__width__400px {
    width: 400px;
}

.element__width__700px {
    width: 700px;
}

.element__min-width__200px {
    min-width: 200px;
}