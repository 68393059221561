.onboarding-task__wrapper {
    width: 100%;
    border: 1px solid var(--colorAdjust20);
    border-radius: 4px;
}

.onboarding-task {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;

    &:not(:last-child) {
        border-bottom: 1px solid var(--colorAdjust20);
    }

    &:hover {
        background-color: var(--colorAdjustAlpha10);
    }
}