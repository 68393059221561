@import './../../services/style/settings';

@media screen and (min-width: $md-min-breakpoint) {
    .registration__field-wrapper {
        display: grid;
        column-gap: 20px;
        row-gap: 20px;
        grid-template-columns: 300px 300px;
        grid-template-rows: auto;
        align-content: flex-start;
    }

    .registration__agent-licenses-wrapper, .registration__agent-optional-services {
        grid-column: 1 / span 2;
    }

    

    .registration__section-header {
        grid-column: span 2 / 3;
    }
}

@media screen and (max-width: $sm-max-breakpoint) {
    .registration__field-wrapper {
        display: flex;
        flex-direction: column;
    }

    .registration__field-wrapper > div {
        margin-bottom: 20px;
    }

    .registration__agent-licenses-wrapper {
        display: flex;
        flex-direction: column;
    }
    .registration__agent-optional-services {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .registration__agent-optional-services .registration__service-card {
        display: flex;
        flex-direction: column;

        .service-card__img {
            width: 100%;
            min-height: 215px;
        }
    }
}
.cost-num {
    min-width: 50px;
    display: flex;
    justify-content: end;
}

.registration__optional-marker {
    opacity: 0.5;
    font-style: italic;
    margin-left: 10px;
}

.disclaimer {
    
    font-size: calc(var(--bodyFontSize) * .9) ;
    font-style: italic;
}
.email-exist-modal-text {
    min-height: 112px;
}

span.promoCode {
    padding: 2px;
    border: solid 1px #d3d3d3;
}