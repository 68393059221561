.avatar {
    flex-shrink: 0;
    border-radius: 50%;
    background-color: var(--appTrayBgColor);
    color: var(--colorAdjust0);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-size: cover;

    &.is-clickable {
        &:hover {
            filter: brightness(80%);
            cursor: pointer;
        }
    }


    &.is-lg {
        width: 60px;
        height: 60px;
    }

    &.is-md {
        width: 42px;
        height: 42px;
    }

    &.is-sm {
        width: 32px;
        height: 32px;
    }
}